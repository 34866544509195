import Container from 'react-bootstrap/Container'
import Link from 'next/link'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import NavDropdown from 'react-bootstrap/NavDropdown'
import { useUser } from 'next-firebase-auth'
import { useRouter } from 'next/router'
import BrandLogo from './BrandLogo'
import { useFirestoreUser } from '../../hooks/useFirestoreUser'
import FooterApp from './FooterApp'
import NavLink from './NavLink'
import MenuItemsList from './MenuItemsList'
import { ListingManagerWalkthrough } from '../listing-manager/ListingManagerWalkthrough'
import { IvyStoreWalkthrough } from '../ivystore/IvyStoreWalkthrough'

const LayoutApp = ({ children }) => {
  const authUser = useUser()
  const router = useRouter()
  const isAdmin = authUser.claims?.admin ?? false

  const user = useFirestoreUser()
  const leadsAccess = user.private?.subscription?.leads_access

  const crosslistingMenuItems = [
    {
      available: true,
      href: '/app/listing-manager',
      label: 'Listing Manager'
    },
    {
      available: true,
      href: '/app/store/settings',
      label: 'Ivy Store'
    },
    {
      available: true,
      href: '/app/sales-tracker',
      label: 'Sales Tracker'
    },
  ]

  const userMenuItems = [
    {
      available: true,
      href: '/app/subscription',
      label: 'Subscription'
    },
    {
      available: true,
      href: '/app/settings',
      label: 'Settings'
    }
  ]

  const adminMenuItems = [
    {
      // available: authUser.claims?.leads === true,
      available: leadsAccess,
      href: '/leads',
      label: 'Lead Cannon'
    },
    {
      available: authUser.claims?.messages === true,
      href: '/messages',
      label: 'Message Blast'
    },
    {
      available: isAdmin,
      href: '/app/pickup/admin',
      label: 'Pickup Admin'
    },
    {
      available: isAdmin,
      href: '/app/store/admin',
      label: 'Store Admin'
    }
  ].filter(item => item.available)

  return (
    <>
      <Navbar
        bg='white'
        variant='light'
        expand='md'
        className='py-3 py-md-2 mb-sm-2'
        collapseOnSelect
      >
        <Container>
          <Link
            href={'/app'}
            prefetch={false}
            legacyBehavior
          >
            <Navbar.Brand
              className='d-flex gap-3 me-4'
              href='/'
            >
              <BrandLogo />
            </Navbar.Brand>
          </Link>
          <Navbar.Toggle aria-controls='basic-navbar-nav' />
          <Navbar.Collapse id='basic-navbar-nav'>
            <Nav
              className='me-auto align-items-md-center mt-4 mt-md-0 ms-md-3'
              activeKey={router.pathname}
            >
              {crosslistingMenuItems.map(item => <NavLink key={item.href} href={item.href}>{item.label}</NavLink>)}
            </Nav>
            <Nav
              activeKey={router.pathname}
              className={'d-flex align-items-center'}
            >
              <NavLink href={'/'}>
                To website
              </NavLink>

              {authUser.id ? (
                <NavDropdown
                  title='Account'
                  className='my-0 position-relative'
                  style={{ zIndex: 1050 }}
                >
                  <MenuItemsList items={adminMenuItems} />
                  <MenuItemsList items={userMenuItems} divide={adminMenuItems.length > 0} />
                </NavDropdown>
              ) : (
                <NavLink href='/app/signin'>Sign In</NavLink>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Container className='d-flex flex-column'>
        {/*<NewsBanner />*/}

        {children}

        {/* those tools consist of multiple page, wo we define walkthrough high-level */}
        <ListingManagerWalkthrough />
        <IvyStoreWalkthrough />

        <FooterApp />
      </Container>
    </>
  )
}

export default LayoutApp
