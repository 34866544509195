import Container from 'react-bootstrap/Container'
import Navbar from 'react-bootstrap/Navbar'
import { useRouter } from 'next/router'
import BrandLogo from './BrandLogo'
import Nav from 'react-bootstrap/Nav'
import NavLink from './NavLink'
import FooterCustomer from './FooterCustomer'

const LayoutCustomer = ({ children }) => {
  const router = useRouter()

  const fullWidthPathNames = [
    '/',
    '/[stateSlug]',
    '/[stateSlug]/[citySlug]',
    '/store',
  ]

  const isFullWidth = fullWidthPathNames.includes(router.pathname)

  return (
    <>
      <Navbar
        bg={'transparent'}
        variant={'light'}
        expand="md"
        className={`py-3 py-md-2`}
        collapseOnSelect
      >
        <Container>
          <Navbar.Brand
            className="d-flex gap-3 me-4"
            href="/"
          >
            <BrandLogo subtitle={'Sofas & Couches'} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav"/>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav
              className="me-auto align-items-md-center mt-4 mt-md-0 ms-md-3"
              activeKey={router.pathname}
            >
              <NavLink href="/products">
                Products
              </NavLink>
              <NavLink href="/pickup">
                Pickup my Couch
              </NavLink>
            </Nav>
            <Nav activeKey={router.pathname}>
              <NavLink href="/resellers">
                For Resellers
              </NavLink>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      {isFullWidth && children}
      {!isFullWidth && (
        <Container className="d-flex flex-column">
          {children}
        </Container>
      )}

      <Container className='d-flex flex-column'>
        <FooterCustomer />
      </Container>
    </>
  )
}

export default LayoutCustomer
