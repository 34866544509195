import { useEffect } from 'react'
import { useRouter } from 'next/router'
import LayoutCustomer from './LayoutCustomer'
import LayoutReseller from './LayoutReseller'
import LayoutApp from './LayoutApp'
import { EmptyLayout } from './empty-layout'
import * as gtag from '../../lib/gtag'

const resellerPagesUrls = [
  // index
  '/resellers',
  // landings
  '/marketplace-notifications',
  '/listing-manager',
  '/ivy-store',
  '/ivy-industry',
  '/sales-tracker',
  '/unreleased-landings',
  // other pages
  '/pricing',
  '/resources',
  '/blog',
  '/faq',
  '/about',
  // sign in
  '/app/signin',
]

export default function Layout ({ children }) {
  const router = useRouter()

  // Empty Layout setup (its made for a web-view app pages)
  const enableLayout = router.query['enable-layout'] !== 'false' &&
    localStorage.getItem('enable-layout') !== 'false' &&
    !['/couch-pickup'].includes(router.asPath)

  useEffect(() => {
    if (enableLayout === false) {
      localStorage.setItem('enable-layout', 'false')
    } else {
      localStorage.removeItem('enable-layout')
    }
  }, [enableLayout])

  // We have 4 layouts
  // 1. customer facing layout
  // 2. reseller facing layout
  // 3. layout for logged-in users
  // 4. empty layout

  const showResellerLayout = resellerPagesUrls.some(url => router.asPath.startsWith(url))
  const showAppUrl = router.asPath.startsWith('/app')

  const LayoutComponent = enableLayout
    ? (
      showResellerLayout
        ? LayoutReseller
        : (
          showAppUrl
            ? LayoutApp
            : LayoutCustomer
        )
    )
    : EmptyLayout

  // Set up the loading progress bar
  useEffect(() => {
    router.events.on('routeChangeComplete', gtag.pageview)
    return () => {
      router.events.off('routeChangeComplete', gtag.pageview)
    }
  }, [router.events])

  // Custom background color for some pages
  useEffect(() => {
    if (router.asPath.startsWith('/app/sales-tracker')) {
      document.body.style.backgroundColor = '#f0f1f3' // grayish
    } else if (router.asPath.startsWith('/pricing')) {
      document.body.style.backgroundColor = '#f0f3f1' // greenish
    } else {
      document.body.style.backgroundColor = 'white'
    }
  }, [router.asPath])

  return (
    <LayoutComponent>
      {children}
    </LayoutComponent>
  )
}