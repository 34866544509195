import Link from 'next/link'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import BrandLogo from './BrandLogo'

const FooterApp = () => {
  return (
    <div className='py-4 py-lg-5 px-4 px-lg-5 my-3 bg-primary text-white br-3 mt-5'>
      <Row>
        <Col xs={12}>
          <div className='d-flex justify-content-center'>
            <BrandLogo big/>
          </div>
          <div className='mb-3 mt-4 text-center'>
            We are on a mission to create opportunities for people.
          </div>
          <div className='text-center text-muted'>
            © 2025 — Ivy Flip — <Link href='/legal/privacy-policy' prefetch={false}>View Privacy Policy</Link>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default FooterApp