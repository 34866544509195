import Link from 'next/link'
import Nav from 'react-bootstrap/Nav'

export default function NavLink ({ href, children }) {
  return (
    <Link
      href={href}
      prefetch={false}
      legacyBehavior
    >
      <Nav.Link
        className='p-3 my-0'
        href={href}
      >
        {children}
      </Nav.Link>
    </Link>
  )
}
