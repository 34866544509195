import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import { useUser } from 'next-firebase-auth'
import { useRouter } from 'next/router'
import BrandLogo from './BrandLogo'
import FooterReseller from './FooterReseller'
import NavLink from './NavLink'
import { useEffect, useState } from 'react'
import NavDropdown from 'react-bootstrap/NavDropdown'
import MenuItemsList from './MenuItemsList'

const Header = ({ fixed }) => {
  const authUser = useUser()
  const router = useRouter()

  const productMenuItems = [
    { href: '/marketplace-notifications', label: 'Marketplace Notifications' },
    { href: '/listing-manager', label: 'Listing Manager' },
    { href: '/ivy-store', label: 'Ivy Store' },
    { href: '/sales-tracker', label: 'Sales Tracker' }
  ]

  const restItems = [
    { href: '/ivy-industry', label: 'Ivy Industry' },
    { href: '/pricing', label: 'Pricing' },
    { href: '/resources', label: 'Resources' },
    { href: '/blog', label: 'Blog' },
    { href: '/faq', label: 'FAQ' },
    { href: '/about', label: 'About' }
  ]

  const [isScrolled, setIsScrolled] = useState(false)
  useEffect(() => {
    if (!fixed) {
      return
    }
    window.onscroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true)
      } else {
        setIsScrolled(false)
      }
    }
  }, [router.asPath])

  return (
    <Navbar
      bg={(isScrolled || !fixed) ? 'white' : 'transparent'}
      fixed={fixed ? 'top' : null}
      variant={(isScrolled || !fixed) ? 'light' : 'dark'}
      expand='md'
      className={`py-3 py-md-2`}
      collapseOnSelect
    >
      <Container >
        <Navbar.Brand
          className='d-flex gap-3 me-4'
          href='/resellers'
        >
          <BrandLogo subtitle={'Sell & Earn'} />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls='basic-navbar-nav' />
        <Navbar.Collapse id='basic-navbar-nav'>
          <Nav
            className='me-auto align-items-md-center mt-4 mt-md-0 ms-md-3'
            activeKey={router.pathname}
          >
            {(isScrolled || !fixed) && (
             <>
               <NavDropdown
                 title='Products'
                 className='me-2 ms-3 ms-md-0'
                 style={{ zIndex: 1050 }}
               >
                 <MenuItemsList items={productMenuItems} />
               </NavDropdown>

               {restItems.map(item => (
                 <NavLink
                   key={item.href}
                   href={item.href}
                 >
                   {item.label}
                 </NavLink>
               ))}
             </>
            )}
          </Nav>
          <Nav activeKey={router.pathname}>
            <NavLink href="/">
              For Customers
            </NavLink>

            {authUser.id ? (
              <NavLink href='/app'>
                Dashboard
              </NavLink>
            ) : (
              <NavLink href='/app/signin'>
                Sign In
              </NavLink>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

const LayoutReseller = ({ children }) => {
  const router = useRouter()
  const isProductLanding = router.asPath.startsWith('/unreleased-landings/') ||
    [
      '/ivy-industry/',
      '/ivy-store/',
      '/sales-tracker/',
    ].includes(router.asPath)

  return (
    <>
      <Header fixed={isProductLanding} />

      {isProductLanding && children}
      {!isProductLanding && (
        <Container>
          {children}
        </Container>
      )}

      <Container className='d-flex flex-column'>
        <FooterReseller />
      </Container>
    </>
  )
}

export default LayoutReseller
