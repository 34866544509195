import { initFirebase } from '../lib/firebase'
import '../styles/bootstrap.min.css'
import 'react-datepicker/dist/react-datepicker.css'
import '../styles/date-picker.css'
import '../styles/gloabl.css'
import { useMemo } from 'react'
import { AuthAction, withUser } from 'next-firebase-auth'
import { useFirestoreUser, UserProvider } from '../hooks/useFirestoreUser'
import Head from 'next/head'
import Script from 'next/script'
import NextNProgress from 'nextjs-progressbar'
import { Toaster } from 'react-hot-toast'
import ErrorBoundary from '../components/ErrorBoundary'
import Layout from '../components/layouts/Layout'

initFirebase()

const RedditPixel = () => {
  const user = useFirestoreUser()
  const userEmailAdvancedMatchingEntry = useMemo(() => user?.email ? `,"email":"${user.email}"` : '', [user])
  const externalIdAdvancedMatchingEntry = useMemo(() => user?.id ? `"externalId":"${user.id}"` : '', [user])

  return (
    <Script
      strategy="lazyOnload"
      dangerouslySetInnerHTML={{
        __html: `!function(w,d){if(!w.rdt){var p=w.rdt=function(){p.sendEvent?p.sendEvent.apply(p,arguments):p.callQueue.push(arguments)};p.callQueue=[];var t=d.createElement("script");t.src="https://www.redditstatic.com/ads/pixel.js",t.async=!0;var s=d.getElementsByTagName("script")[0];s.parentNode.insertBefore(t,s)}}(window,document);rdt('init','${process.env.REDDIT_ADVERTISER_ID}', {"optOut":false,"useDecimalCurrencyValues":true${userEmailAdvancedMatchingEntry}${externalIdAdvancedMatchingEntry}});rdt('track', 'PageVisit');`
      }}
    />
  )
}

const App = ({ Component, pageProps }) => {
  return (
    <div>
      <ErrorBoundary>
        <UserProvider>
          <Head>
            <title>Ivy Flip</title>
            <link rel='icon' href='/favicon.ico' />
            <meta
              property='og:locale'
              content='en_EN'
              key='og:locale'
            />
            <meta
              property='og:site_name'
              content='Ivy'
              key='og:site_name'
            />
            <meta
              property='og:type'
              content='website'
              key='og:type'
            />
            <meta name='viewport' content='width=device-width,initial-scale=1' />
            <link rel='preconnect' href='https://fonts.googleapis.com' />
            <link rel='preconnect' href='https://fonts.gstatic.com' crossOrigin="crossOrigin" />
            <link href='https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap' rel='stylesheet' />
            <RedditPixel />
          </Head>
          <Layout>
            <NextNProgress color="#4bbf73" />
            <Toaster position="bottom-left" />
            <Component {...pageProps} />
          </Layout>
        </UserProvider>
      </ErrorBoundary>
    </div>
  )
}

export default withUser({
  whenUnauthedBeforeInit: AuthAction.SHOW_LOADER,
  Loader: () => null
})(App)
