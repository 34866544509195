import Button from 'react-bootstrap/Button'
import { useEffect, useState } from 'react'
import {
  faArrowLeft,
  faArrowRight,
  faListNumeric,
  faQuestionCircle,
  faTimes
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Badge from 'react-bootstrap/Badge'
import { useUser } from 'next-firebase-auth'

export function WalkthroughModal ({ localStorageKey, title, chapters }) {
  const authUser = useUser()

  const keyDismiss = `${localStorageKey}-${authUser.id}-walkthrough-dismissed`
  const keyChapter = `${localStorageKey}-${authUser.id}-walkthrough-chapter`

  // we add the menu pseudo-chapter at the end
  const chaptersWithMenu = [
    ...chapters,
    {
      title: 'Menu'
    }
  ]

  // on first load, show the modal asking if the user wants to see the walkthrough
  // if the user clicks "No" - we do not show the modal again, but keep the FAB
  const dismissed = localStorage.getItem(keyDismiss)
  const [isWalkthroughVisible, setIsWalkthroughVisible] = useState(!dismissed)

  const storedChapter = parseInt(localStorage.getItem(keyChapter) ?? 0)
  const [activeChapterIndex, setActiveChapterIndex] = useState(storedChapter)
  const activeChapter = chaptersWithMenu[activeChapterIndex]

  useEffect(() => {
    localStorage.setItem(keyChapter, activeChapterIndex.toString())
  }, [activeChapterIndex])

  return (
    <>
      {/* FAB */}
      {!isWalkthroughVisible && (
        <div
          className={'position-fixed end-0 bottom-0 me-3 mb-3'}
          style={{ zIndex: 1050 }}
        >
          <Button
            variant={'info'}
            onClick={() => setIsWalkthroughVisible(true)}
          >
            Walkthrough
            <FontAwesomeIcon
              icon={faQuestionCircle}
              width={16}
              height={16}
              className={'ms-2'}
            />
          </Button>
        </div>
      )}

      {/* Walkthrough */}
      {isWalkthroughVisible && (
        <div
          className={'position-fixed end-0 bottom-0 me-3 mb-3'}
          style={{
            width: 500,
            maxWidth: 'calc(100vw - 2rem)',
            zIndex: 1050,
          }}
        >
          <div className={'border border-5 border-info br-3 p-3 bg-white'}>
            <div className={'d-flex flex-column gap-3'}>
              {/* Header */}
              <div className={'d-flex align-items-center gap-3'}>
                {activeChapterIndex !== chaptersWithMenu.length - 1 && (
                  <Badge
                    pill
                    bg={'info'}
                    onClick={() => setActiveChapterIndex(chaptersWithMenu.length - 1)}
                    style={{ cursor: 'pointer' }}
                  >
                    <FontAwesomeIcon
                      icon={faListNumeric}
                      width={16}
                      height={16}
                      className={'me-1'}
                    />

                    Step {activeChapterIndex + 1} / {chapters.length}
                  </Badge>
                )}
                <div className={'text-muted'}>
                  {title}
                </div>
                <Button
                  variant={'light'}
                  className={'ms-auto'}
                  onClick={() => {
                    localStorage.setItem(keyDismiss, 'true')
                    setIsWalkthroughVisible(false)
                  }}
                >
                  <FontAwesomeIcon
                    icon={faTimes}
                    width={16}
                    height={16}
                  />
                </Button>
              </div>
              {/* Step Header */}
              <div className={'d-flex align-items-baseline gap-2 fs-5 '}>
                <div>
                  {activeChapter.title}
                </div>
              </div>
              {/* Body */}
              <div
                className={'d-flex flex-column gap-3'}
                style={{
                  maxHeight: '60vh',
                  overflowY: 'auto'
                }}
              >
                {activeChapter.text}

                {/* menu */}
                {(activeChapterIndex === chaptersWithMenu.length - 1) && (
                  <div>
                    {chapters.map((chapter, chapterIndex) => (
                      <div key={`chapter-${chapterIndex}`}>
                        <Button
                          variant={'link'}
                          onClick={() => setActiveChapterIndex(chapterIndex)}
                        >
                          {chapterIndex + 1}. {chapter.title}
                        </Button>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              {/* Buttons */}
              <div className={'d-flex justify-content-between gap-2'}>
                {activeChapterIndex === 0 && (
                  <>
                    <div/>
                    <Button
                      variant={'primary'}
                      onClick={() => setActiveChapterIndex(activeChapterIndex + 1)}
                    >
                      Start Walkthrough
                      <FontAwesomeIcon
                        icon={faArrowRight}
                        width={16}
                        height={16}
                        className={'ms-2'}
                      />
                    </Button>
                  </>
                )}

                {(activeChapterIndex > 0 && activeChapterIndex !== chaptersWithMenu.length - 1) && (
                  <>
                    <Button
                      variant={'light'}
                      onClick={() => setActiveChapterIndex(activeChapterIndex - 1)}
                    >
                      <FontAwesomeIcon
                        icon={faArrowLeft}
                        width={16}
                        height={16}
                        className={'me-2'}
                      />
                      Previous
                    </Button>

                    {(activeChapterIndex < chapters.length - 1) && (
                      <Button
                        variant={'primary'}
                        onClick={() => setActiveChapterIndex(activeChapterIndex + 1)}
                      >
                        Next
                        <FontAwesomeIcon
                          icon={faArrowRight}
                          width={16}
                          height={16}
                          className={'ms-2'}
                        />
                      </Button>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}