import { useRouter } from 'next/router'
import Link from 'next/link'
import { WalkthroughModal } from '../WalkthroughModal'

const chapters = [
  {
    title: 'Welcome to IvyStore',
    text: <>
      <div>
        <strong>Ivy Store</strong> is the ultimate couch flipping storefront, fully loaded with built-in marketing power
        to get you seen and selling—fast.
      </div>
      <div>
        This walkthrough will guide you through the primary features and help you get started quickly.
      </div>
      <div>
        You can hide this tutorial at any point and bring it back by clicking the Walkthrough button in the bottom-right
        corner.
      </div>
    </>,
  },
  {
    title: 'Store Settings',
    text: <>
      <div>
        Choose the <strong>Store Name</strong>. This will be the name
        that your customers will see. <strong>Store Url</strong> will be automatically generated based on the name you
        choose. You can change it if you want.
      </div>
      <div>
        Select a <strong>State and City</strong> where your store is located. This will help customers find your store.
      </div>
      <div>
        Set contact details for your store. This will be displayed on your store page.
      </div>
      <div>
        From here you are ready to go – click <strong>Save</strong> button on the bottom of the page.
      </div>
      <div>
        To make your store even more attractive, you can set a cover image and texts for Hero and About sections.
      </div>
    </>,
  },
  {
    title: 'Inventory',
    text: <>
      <div>
        Switch to the <strong>Inventory</strong> tab to add your products.
      </div>
      <div>
        Click <strong>+ Listing</strong> to create a new item on your Ivy Store.
      </div>
      <div>
        Fill in the details for your product: title, description, price, and upload images.
        Other fields are optional, but more information will help your customers make a decision.
      </div>
      <div>
        You may sort your listings by dragging and dropping them.
        Update the price and unpublish listings by clicking on the three dots icon.
      </div>
      <div>
        You may also publish your listing to other maketplace.
        To do this, go to a <Link href={'/app/listing-manager'}>Listing Manager</Link>.
      </div>
    </>,
  },
]

export function IvyStoreWalkthrough () {
  const router = useRouter()

  const isFabVisible = router.asPath.includes('/app/store')
  if (!isFabVisible) {
    return
  }

  return (
    <>
      <WalkthroughModal
        title={'IvyStore Walkthrough'}
        chapters={chapters}
        localStorageKey={`ivystore`}
      />
    </>
  )
}