import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import { useEffect, useState } from 'react'
import { useUser } from 'next-firebase-auth'
import * as fns from 'firebase/functions'
import { firebaseFunctions } from '../lib/firebase'
import * as track from '../lib/track'

const SubscribeViaEmailForm = ({
  onSubscribe,
  shownInFooter = false,
  label = 'Interested in more flipping articles?'
}) => {
  const authUser = useUser()
  const [value, setValue] = useState(authUser?.email ?? '')
  const [isSending, setIsSending] = useState(false)
  const [validationIsOn, setValidationIsOn] = useState(false)
  const [showForm, setShowForm] = useState(false)
  const [status, setStatus] = useState({
    isError: false,
    message: ''
  })

  useEffect(() => {
    const alreadySubscribed = window.localStorage.getItem('subscribeToBlog:success')
    if (!alreadySubscribed) {
      setShowForm(true)
    }
  }, [])

  async function handleSubmit (e) {
    e.preventDefault()

    if (!e.currentTarget.checkValidity()) {
      setIsSending(false)
      setValidationIsOn(true)
      return
    }

    setIsSending(true)

    try {
      const response = await fns.httpsCallable(firebaseFunctions, 'app-subscribeToNewsletter')({
        email: value
      })
      if (response.data?.error) {
        setStatus({
          message: response.data.error ?? 'Something went wrong. Please try again or contact hello@ivyflip.com',
          isError: true
        })
        return
      }

      track.subscribeToNewsletter(value)

      if (typeof onSubscribe === 'function') {
        onSubscribe()
      }

      window.localStorage.setItem('subscribeToBlog:success', JSON.stringify({ [value]: +new Date() }))

      setStatus({
        message: 'Your were successfully subscribed.',
        isError: false
      })
    } catch (error) {
      setStatus({
        message: error.message ?? 'Something went wrong, please try again.',
        isError: true
      })
    } finally {
      setIsSending(false)
      setValidationIsOn(false)
    }
  }

  if (showForm === false) {
    return (
      <></>
    )
  }

  return (
    <div className={shownInFooter ? '' : 'bg-light br-3'}>
      <Row>
        <Col xs={12}>
          <div
            className={shownInFooter
              ? 'd-flex flex-column'
              : 'p-3 p-md-4 p-lg-5 d-flex flex-column align-items-center text-center'
            }
          >
            <div className={shownInFooter ? 'fs-4 fw-semibold mb-3' : 'fs-4 fw-bold'}>
              {label}
            </div>
            <p className={shownInFooter ? 'mb-3 text-muted' : `lead mb-2`}>
              Subscribe and get exclusive content:
            </p>

            <Form
              noValidate
              validated={validationIsOn}
              onSubmit={handleSubmit}
              className={'d-flex flex-column gap-1'}
              style={{ maxWidth: '100%', width: '300px' }}
            >
              <Form.Control
                required
                disabled={authUser?.email || isSending}
                type='email'
                placeholder='your@email.com'
                defaultValue={value}
                onChange={(e) => setValue(e.target.value)}
              />
              <Button
                type='submit'
                variant='success'
                disabled={isSending}
              >
                {isSending ? 'Subscribing...' : 'Subscribe'}
              </Button>

              { status.message && (
                <div className={status.isError ? 'text-danger' : 'text-success'}>
                  {status.message}
                </div>
              )}
            </Form>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default SubscribeViaEmailForm
