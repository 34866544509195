import Image from 'next/legacy/image'

const BrandLogo = ({ big = false, subtitle }) => {
  return (
    <div className={`d-flex ${subtitle ? 'align-items-start' : 'align-items-center'} gap-2`}>
      <Image
        src='/img/logo-transparent.svg'
        alt='Ivy Flip'
        width={big ? 48 : 32}
        height={big ? 48 : 32}
        layout='fixed'
        priority
      />
      <div
        className={subtitle ? 'pt-1' : ''}
        style={{ lineHeight: 1.2 }}
      >
        <div className={'fw-semibold ' + (big ? 'fs-3' : '')}>
          Ivy Flip
        </div>

        {subtitle && (
          <div
            className={'text-muted'}
            style={{ fontSize: 14 }}
          >
            {subtitle}
          </div>
        )}
      </div>
    </div>
  )
}

export default BrandLogo
